  <template>
     <div id="app" class="main">
         <h2 class="no-print">Bingo Card Generator</h2>
         <div class="controls no-print"> 
           <input type="number" v-model="numberOfBoards" class="no-print input-field" min="1" max="6">
           <button class="no-print generate-button" v-on:click="prepareBoard">Generate Boards</button>
         </div>
         <div v-if="boardReady">
          <div v-for="(board, index) in boards" class="bingo-grid" :key="board" v-bind:class="{'break-page': index % 2 != 0}">
                <div class="bingo-heading left-border">B</div>
                <div class="bingo-heading left-border">I</div>
                <div class="bingo-heading left-border">N</div>
                <div class="bingo-heading left-border">G</div>
                <div class="bingo-heading left-border">O</div>
                <div class="bingo-number left-border" v-for="val in board.boardValues" :key="val">{{val}}</div>
          </div>
         </div>
         <div v-if="!boardReady && loading">
             <div class="lds-spinner"></div>
         </div>
     </div>
  </template>
  <script>
      export default {
          name: 'BingoCard', 
          el: '#app',
          data () {
              return {
                bValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                iValues: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                nValues: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
                gValues: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
                oValues: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75],
                bRow: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                iRow: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                nRow: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
                gRow: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
                oRow: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75],
                boardReady: false,
                boardValues: [],
                numberOfBoards: 1,
                boards: [],
                loading: false
              }
          }, 
          methods: {
              prepareBoard: function() {
                 this.boardReady = false;
                 this.loading = true;
                 for (let j = 0; j < this.numberOfBoards; j++) {
                    this.boardValues = [];
                    for (let i = 0; i < 25; i++) {
                        if (i == 12) {
                            this.boardValues[i] = 'Free';
                        } else if (i % 5 == 0) {
                            let index = Math.floor(Math.random() * this.bRow.length);                        
                            this.boardValues[i] = this.bRow[index];
                            this.bRow.splice(index, 1);
                        } else if (i % 5 == 1) {
                            let index = Math.floor(Math.random() * this.iRow.length);
                            this.boardValues[i] = this.iRow[index];
                            this.iRow.splice(index, 1);
                        } else if (i % 5 == 2) {
                            let index = Math.floor(Math.random() * this.nRow.length);
                            this.boardValues[i] = this.nRow[index];
                            this.nRow.splice(index, 1);
                        } else if (i % 5 == 3) {
                            let index = Math.floor(Math.random() * this.gRow.length);
                            this.boardValues[i] = this.gRow[index];
                            this.gRow.splice(index, 1);
                        } else if (i % 5 == 4) {
                            let index = Math.floor(Math.random() * this.oRow.length);
                            this.boardValues[i] = this.oRow[index];
                            this.oRow.splice(index, 1);
                        }
                    }
                    
                    this.boards[j] = {
                        boardValues : this.boardValues
                    }

                    this.bRow = [];
                    this.bRow.push(...this.bValues);
                    this.iRow = [];
                    this.iRow.push(...this.iValues);
                    this.nRow = [];
                    this.nRow.push(...this.nValues);
                    this.gRow = [];
                    this.gRow.push(...this.gValues);
                    this.oRow = [];
                    this.oRow.push(...this.oValues);


                }

                 this.loading = false;
                 this.boardReady = true;
              }
          }
      };
  </script>

  <style>
      body {
        font-family: 'Helvetica', Arial, sans-serif;
      }

      .main {
        text-align: center;
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      .main h2 {
        flex-basis: 100%;
      }

      .main .controls {
        flex-basis: 100%;
        display:flex; 
        flex-wrap: nowrap;
        align-self: center;
        justify-content: center;
      }

      .bingo-grid {
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        grid-template-rows: 16% 17% 16% 17% 16% 17%;
        border: 2px #121212 solid;
        border-radius: 3px;
        width: 375px;
        height: 375px;
        margin-top: 75px;
        margin-left: auto;
        margin-right: auto;
      }

      .bingo-heading {
        border-bottom: 1px #121212 solid;
        background: rgb(162, 207, 236);
        text-align: center;
        vertical-align: middle;
        padding: 25px 0;
        font-weight: 600;
      }

      .bingo-number {
        text-align: center;
        vertical-align: middle;
        border-top: 1px #121212 solid;
        padding: 25px 0;
      }

      .left-border:not(:first-of-type) {
        border-left: 1px #121212 solid;
      }

      .generate-button {
        font-size: 15px;
        text-decoration: none;
        color: #fff;
        background-color: #B00B69;
        text-align: center;
        letter-spacing: .5px;
        transition: background-color .2s ease-out;
        cursor: pointer;
        outline: 0;
        border: none;
        border-radius: 2px;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
        text-transform: uppercase;
        vertical-align: middle;
        -webkit-tap-highlight-color: transparent;
      }

      .input-field {
        height: 30px;
        line-height: 30px;
        border-radius: 3px;
        margin-right: 10px;
        padding-left: 5px;
      }

      .break-page {
          page-break-after: always;
      }

      .caller {
           position: absolute;
           top: 10px;
           right: 10px;
           cursor: pointer;
           color: #fff;
           background-color: #B00B69;
           text-decoration: none;
           padding: 10px 15px;
           border-radius: 4px;
      }

      @media print {
        .no-print {
          display:none;
        }
      }
  </style>

<template>
  <nav class="no-print">
    <router-link to="/" :key="$route.fullPath">Home</router-link>
    <router-link to="/Caller" :key="$route.fullPath">Caller</router-link>
  </nav>
  <div>
  <router-view />
  </div>
</template>

<script>

//import BingoCards from '@/components/BingoCards.vue'
//import Caller from '@/components/Caller.vue'
import router from '@/router/index.js'

export default {
  router,
  name: 'App',
  components: {
    //BingoCards,
    //Caller
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 10px;
  }

  nav {
    display: flex;
    flex-wrap: no-wrap;
    justify-content : flex-end;
  }

  nav a {
    margin: 5px 10px;
    padding: 5px 10px;
    color: white;
    background: #b00b69;
    border-radius : 3px;
    text-transform: uppercase;
  }

  a:link, a:visited, a:hover, a:active {
    text-decoration: none;
  }

  @media print {
    .no-print {
      display:none;
    }
  }
</style>

import { createRouter, createWebHistory } from 'vue-router'
import BingoCards from '@/components/BingoCards.vue'
import Caller from '@/components/Caller.vue'

const routes = [
    {
        path : '/',
        name : 'BingoCards',
        component : BingoCards
    },
    {
        path : '/Caller',
        name : 'Caller',
        component : Caller
    }
];

const router = createRouter({ history: createWebHistory(), routes });

export default router;
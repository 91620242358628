<template>
    <div id="caller">
        <h1>Bingo</h1>
        <button v-on:click="drawNumber">Draw Number</button>
        <button v-on:click="initGame">Reset</button>
        <div id="controls">
            <h3>Current Number</h3>
            <input type="text" readonly="true" id="currentNumber" v-model="currentNumber"/>
        </div>
        <div class="called-numbers-container">
            <span id="bCol">
                <h2>B</h2>
                <span v-for="bnum in bCalledNumbers" :key="bnum">
                    {{bnum}}
                </span>
            </span>
            <span id="iCol">
                <h2>I</h2>
                <span v-for="inum in iCalledNumbers" :key="inum">
                    {{inum}}
                </span>
            </span>
            <span id="nCol">
                <h2>N</h2>
                <span v-for="nnum in nCalledNumbers" :key="nnum">
                    {{nnum}}
                </span>
            </span>
            <span id="gCol">
                <h2>G</h2>
                <span v-for="gnum in gCalledNumbers" :key="gnum">
                    {{gnum}}
                </span>
            </span>
            <span id="oCol">
                <h2>O</h2>
                <span v-for="onum in oCalledNumbers" :key="onum">
                    {{onum}}
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Caller', 
    el: '#caller',
    data () {
        return {
            numbersReady: false,
            availableNumbers: [],
            bCalledNumbers: [],
            iCalledNumbers: [],
            nCalledNumbers: [],
            gCalledNumbers: [],
            oCalledNumbers: [],
            currentNumber: ''
        }
    },
    mounted() {
        this.initGame();
    },
    methods : {
        initGame : function() {
            this.availableNumbers = [];
            for (let i = 1; i <=75; i++) {
                this.availableNumbers.push(i);
            }
            this.bCalledNumbers = [];
            this.iCalledNumbers = [];
            this.nCalledNumbers = [];
            this.gCalledNumbers = [];
            this.oCalledNumbers = [];
            this.currentNumber = '';
        },
        drawNumber : function() {
            let arrSize = this.availableNumbers.length;
            let chosenIndex = Math.floor(Math.random() * arrSize);
            let calledNum = this.availableNumbers[chosenIndex];

            this.availableNumbers.splice(chosenIndex, 1);

            if (!calledNum) {
                this.currentNumber = "Game Over";
            } else if (calledNum <= 15) {
                this.currentNumber = 'B ' + calledNum;
                this.bCalledNumbers.push(Number(calledNum));
                this.bCalledNumbers.sort((a,b) => a-b);
            } else if (calledNum > 15 && calledNum <= 30) {
                this.currentNumber = 'I ' + calledNum;
                this.iCalledNumbers.push(calledNum);
                this.iCalledNumbers.sort();
            } else if (calledNum > 30 && calledNum <= 45) {
                this.currentNumber = 'N ' + calledNum;
                this.nCalledNumbers.push(calledNum);
                this.nCalledNumbers.sort();
            } else if (calledNum > 45 && calledNum <= 60) {
                this.currentNumber = 'G ' + calledNum;
                this.gCalledNumbers.push(calledNum);
                this.gCalledNumbers.sort();
            } else {
                this.currentNumber = 'O ' + calledNum;
                this.oCalledNumbers.push(calledNum);
                this.oCalledNumbers.sort();
            }
        }
    },
};
</script>
<style>
    body {
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
    }

    button {
        font-size: 15px;
        text-decoration: none;
        color: #fff;
        background-color: #B00B69;
        text-align: center;
        letter-spacing: .5px;
        transition: background-color .2s ease-out;
        cursor: pointer;
        outline: 0;
        border: none;
        border-radius: 2px;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
        text-transform: uppercase;
        vertical-align: middle;
        -webkit-tap-highlight-color: transparent;
        margin: 5px 10px;
    }

    input {
        height: 50px;
        font-size: 36px;
        border-radius: 4px;
        width: 15%;
        margin: 0 15px;
        padding: 2px 5px;
    }

    .called-numbers-container {
        display: flex; 
        flex-direction: row; 
        flex-wrap: wrap;
        width: 60%;
        margin: 0 20%;
    }
    .called-numbers-container span {
        flex-basis: 20%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .called-numbers-container span h2 {
        font-weight:bold;
        flex-basis:100%;
        text-align: center;
        border-bottom: 1px #B00B69 solid;
        min-height: 50px;
        height: 50px;
    }

    .called-numbers-container span span {
        flex-basis: 100%;
        text-align: center;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 15px;
        height: 50px;
    }
</style>